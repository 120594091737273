// import { CURRENT_USER, FETCH_EVENTS, FETCH_SPEAKERS, FETCH_TOPICS } from '../../graphql/queries';
import Card from "react-bootstrap/Card";
import SpeakerList from "./SpeakerList";
import EventList from "./EventList";
import { ADD_BOOKING } from "../../graphql/mutations";
import { CURRENT_USER } from "../../graphql/queries";
import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import "../../styles/bookings/BookingPage.css";

const BookingDetails = () => {
  const [requestor, setRequestor] = useState("");
  const [event, setEvent] = useState("");
  const [speaker, setSpeaker] = useState("");

  const [addBooking, { loading, error }] = useMutation(ADD_BOOKING, {
    variables: { requestor, event, speaker },
    onError() {
      console.log("Error Creating a Booking", error);
    },
    refetchQueries: [{ query: CURRENT_USER }]
  });
  return (
    <div className="container-fluid">
      <div
        className="row justify-content-center align-items-center "
        style={{ minHeight: "calc(100vh - 198px)" }}
      >
        <div className="col-12">
          <Card
            className="mt-2 mb-2 mr-auto ml-auto"
            style={{ maxWidth: "600px" }}
          >
            <Card.Header>
              <h4 className="mb-0 text-center">Add Booking</h4>
            </Card.Header>
            <Card.Body>
              <form
                className="row"
                onSubmit={(e) => {
                  e.preventDefault();
                  addBooking();
                }}
              >
                <div className="col-12">
                  <section className="form-group">
                    <label> Requestor </label>

                  <input
                    type="text"
                    value={requestor}
                    placeholder="+ Add Organizer"
                    className="form-control"
                    onChange={(e) => setRequestor(e.currentTarget.value)}
                    required
                  />
                  </section>
                  <section className="form-group">
                    <label> Choose a Speaker </label>

                    <SpeakerList setSpeaker={setSpeaker} />
                  </section>

                         <section className="form-group">
                    <label> Choose and Event </label>
                  
                    <EventList setEvent={setEvent} />
                    
                    <Link to="/event" className="mt-1 d-block">
                    <small>  Or Add a New Event to SheSpeaks Bureau </small>
                    </Link>
                  </section>
               
                </div>
                <div className="col-12 text-center mt-3">
                <input
                    type="submit"
                    value="Submit"
                    disabled={loading}
                    className="btn btn-primary mr-1"
                  
                  />
                  <Link to="/">
                    <button className="btn btn-secondary">Cancel</button>
                  </Link>
                </div>
              </form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
