
import React, {  useState } from "react";
import Button from "../../../node_modules/react-bootstrap/Button";

import SignInSignUpScreen from "../session/SignInSignUpModal";

const LoginBtn = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div>
      <Button 
        className="btn btn-sm  btn-outline-light rounded-pill border-w-2"
        onClick={() => setModalShow(true)}
      >
        Sign In
      </Button >

      <SignInSignUpScreen show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default LoginBtn;
