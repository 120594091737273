import React from 'react';
import SignUpDetails from '../../components/session/SignUpDetails';

const SignUpForm = () => {
  return (
    <div>
      <SignUpDetails/>
    </div>
  );
};

export default SignUpForm;