import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import AuthRoute from './components/util/AuthRoute';
import ProtectedRoute from './components/util/ProtectedRoute';
import HomePage from './pages/homepage/HomePage';
import SignUpForm from './pages/session/SignUpForm';
import LogInForm from './pages/session/LogInForm';
import AboutPage from './pages/about/AboutPage';
import PartnersPage from './pages/partners/PartnersPage';
import ErrorPage from './pages/error/ErrorPage';
import SpeakerForm from './pages/speakers/SpeakerForm';
import EditSpeakerForm from './pages/speakers/EditSpeakerForm';
import SpeakerProfile from './pages/speakers/SpeakerProfile';
import SpeakersPage from './pages/speakers/SpeakersPage';
import EventForm from './pages/events/EventForm';
import EventsPage from './pages/events/EventsPage';
import BookingForm from './pages/bookings/BookingForm';
import PasswordChangeForm from './pages/users/PasswordChangeForm';
import DeleteProfileForm from './pages/speakers/DeleteProfileForm';

import './assets/css/App.css';

const App = () => {
  return (

    <BrowserRouter>
      <Navbar />
      <Switch>
        <AuthRoute path="/signup" component={SignUpForm} />
        <AuthRoute path="/login" component={LogInForm} />
       

        <ProtectedRoute exact path="/password" component={PasswordChangeForm} />
        <ProtectedRoute exact path="/remove" component={DeleteProfileForm} />
        <ProtectedRoute exact path="/speaker" component={SpeakerForm} />
        <ProtectedRoute exact path="/editProfile/:userId" component={EditSpeakerForm} />
        <ProtectedRoute exact path="/event" component={EventForm} />
        <ProtectedRoute exact path="/booking" component={BookingForm} />

        <Route exact path="/" component={HomePage} />

        <Route exact path="/about" component={AboutPage} />
        <Route exact path="/partners" component={PartnersPage} />
        <Route exact path="/events" component={EventsPage} />
        <Route exact path="/speakers" component={SpeakersPage} />
        <Route exact path="/speaker/:handle" component={SpeakerProfile} /> 
        
        <Route path="/" component={ErrorPage} />

      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default App;