import React from 'react';
import "../../styles/about/AboutPage.css";

function AboutPage() {

  return (
    <div style={{ minHeight: "calc(100vh - 177px)" }}>
      <div className="container-fluid about-header">

        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="white-color" style={{marginTop:'10vh'}}>
                About Us
                    </h1>

            </div>

          </div>

        </div>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="row mt-5 mb-5">
            <div className="col-12">
              <p className="mb-4">
                Remember the last time you were at a conference or tech event, looked at the speaker list
                and found it to be lacking in women speakers especially women of color. You are onto
                something. Nearly 70 percent of event speakers worldwide are men and women made up only 28%
                of keynote speakers at Tech Conferences. The statistics for women of color is even more
                bleak, women of color made up only 8% of keynote speakers at Tech Conferences and 80% of
                women of color said they were the only woman of color on a panel.
                    </p>
              <p>
                As Tech Industry and Corporate World are creating or revamping their Diversity, Equity &
                Inclusion (DEI) Policies and Practices there is a need for diverse female voices to be
                included.
                    </p>

            </div>
          </div>

          <div className="row">

            <div className="col-md-12">
              <div className="jumbotron jumbotron-fluid pt-4 pb-4">
                <div className="container">
                  <h6>
                    SheSpeaksBureau.org is an effort by passionate female tech leaders and startup
                    founders to provide a platform to elevate the voices of Female speakers with special
                    focus on Female speakers of Color.


                            </h6>
                </div>
              </div>
            </div>
          </div>


          <div className="row mb-5">
            <div className="col-sm-6">
              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title">How we do it:</h5>
                  <ul className="pl-4">
                    <li>
                      <p className="card-text">Amplify Female Voices</p>
                    </li>
                    <li>
                      <p className="card-text">Diversity Equity & Inclusion as Core</p>
                    </li>
                    <li>
                      <p className="card-text">Global Community advocating for each other</p>
                    </li>
                    <li>
                      <p className="card-text">Partner Ecosystem</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title">How you can help:</h5>

                  <ul className="pl-4">
                    <li>
                      <p className="card-text">Sponsor</p>
                    </li>
                    <li>
                      <p className="card-text">Volunteer</p>
                    </li>
                    <li>
                      <p className="card-text">Share</p>
                    </li>
                    <li>
                      <p className="card-text">Register</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutPage;