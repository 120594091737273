import React from 'react';
import { useState } from "react";
import { useMutation } from '@apollo/react-hooks';
import { LOGIN_USER } from '../../graphql/mutations';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
// import { IS_LOGGED_IN, CURRENT_USER } from '../../graphql/queries';
import '../../styles/session/LogInForm.css';

const LoginDetails  = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const [loginUser, { loading, error }] = useMutation(
    LOGIN_USER,
    {
      variables: {
        email,
        password
      },
      update(cache, { data: { login } }) {
        if (!login) setErrorMessage("Invalid Credentials");
        else {
          cache.writeData({ data: { isLoggedIn: login.loggedIn } });
          localStorage.setItem('token', login.token);
        }
      },
      onError() {
        setErrorMessage("Something Went Wrong", error)
      },
      // refetchQueries: [{ query: CURRENT_USER }, { query: IS_LOGGED_IN }]
    }
  )


  return (
    <div className="container-fluid">
      <div className="row justify-content-center align-items-center " style={{ minHeight: "calc(100vh - 198px)" }}>
        <div className="col-md-8 mt-3 mb-3 ">
          <div className="card bg-pink-gradiant">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <img src={require('../../assets/images/login-illustration.svg').default} alt="loginImage" className="d-block m-auto img-fluid" />
                </div>
                <div className="col-md-6 p-5">
              
              
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    loginUser();
                  }}>
                    <h4 className="text-center">Sign In</h4>
                    <section className="form-group">
                      <label htmlFor="email">Email</label>

                      <input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => { setEmail(e.currentTarget.value); setErrorMessage("") }}
                        className="form-control"
                        required
                      />
                    </section>
                    <section className="form-group">


                      <label htmlFor="password" >Password</label>

                      <input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => { setPassword(e.currentTarget.value); setErrorMessage("") }}
                        className="form-control"
                        required
                      />

                    </section>
                    {

                      errorMessage ? <Alert variant="danger" className="text-center" fade={false}>! {errorMessage}</Alert> : ""
                    }
                    <div className="text-center">
                      <button type="submit" disabled={loading} className=" btn btn-primary mb-3">
                        SIGN IN
                    </button>
                    </div>
                  </form>

                  <div className="text-center">
                    Not a member?
                     <Link to="/signup" className="ml-2">Register here.</Link>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>
      </div>

    </div>


  )
}

export default LoginDetails;