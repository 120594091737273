import React from 'react';
import { FETCH_SPEAKERS } from '../../graphql/queries';
import { useQuery } from '@apollo/react-hooks';

const SpeakerList = ({ setSpeaker }) => {
  const { loading, error, data } = useQuery(FETCH_SPEAKERS);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <select name="speaker" onChange={setSpeaker} className="form-control">
      {data.speakers.map(speaker => (
        <option key={speaker._id} value={speaker._id}>
          {speaker.fname + " " + speaker.lname}
        </option>
      ))}
    </select>
  );
}

export default  SpeakerList;