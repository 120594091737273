import React from 'react';
import AddSpeakerDetails from '../../components/speakers/AddSpeakerDetails';
import '../../styles/speakers/SpeakerPage.css';

const SpeakerForm = () => {

  return (
    <main className="add_speaker_main">      
      <section className="add_speaker_body">
        <div className="speaker_details">
          <AddSpeakerDetails />
        </div>
      </section>
    </main>
  )
}
export default SpeakerForm;