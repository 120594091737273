import Card from "react-bootstrap/Card";
import { CURRENT_USER } from "../../graphql/queries";
import { ADD_EVENT } from "../../graphql/mutations";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import "../../styles/events/EventPage.css";

const EventDetails = () => {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [desc, setDesc] = useState("");
  const [organizer, setOrganizer] = useState("");
  const [location, setLocation] = useState("");
  const [event_date, setEventDate] = useState("");
  const [start_time, setStartTime] = useState("");
  const [end_time, setEndTime] = useState("");

  let history = useHistory();
  const [addEvent, { loading, error }] = useMutation(ADD_EVENT, {
    variables: { name, link, desc, organizer, location, event_date, start_time, end_time },
    onError() {
      console.log("Error Creating an Event", error);
    },
    refetchQueries: [{ query: CURRENT_USER }],
    onCompleted: (data) =>
      history.push("/events"),
  });
  return (
    <div className="container-fluid">
      <div
        className="row justify-content-center align-items-center "
        style={{ minHeight: "calc(100vh - 198px)" }}
      >
        <div className="col-12">
        
        
          <Card
            className="mt-2 mb-2 mr-auto ml-auto"
            style={{ maxWidth: "600px" }}
          >
            <Card.Header>
              <h4 className="mb-0 text-center">Add an Event</h4>
            </Card.Header>
            <Card.Body>
              <form
                className="row"
                onSubmit={(e) => {
                  e.preventDefault();
                  addEvent();
                }}
              >
                <div className="col-12">
                  <section className="form-group">
                    <label> Event Name </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.currentTarget.value)}
                      placeholder="+ Event Name "
                      className="form-control"
                      required
                    />
                  </section>
                  <section className="form-group">
                    <label> Event Link </label>
                    <input
                      type="text"
                      value={link}
                      onChange={(e) => setLink(e.currentTarget.value)}
                      placeholder="+ Event Link "
                      className="form-control"
                      required
                    />
                  </section>
                  <section className="form-group">
                    <label> Description </label>
                    <textarea
                     rows="4"
                      value={desc}
                      onChange={(e) => setDesc(e.currentTarget.value)}
                      placeholder="+ Event Description "
                      className="form-control"
                      required
                    />
                  </section>
                  <section className="form-group">
                    <label> Event Organizer </label>
                    <input
                      type="text"
                      value={organizer}
                      onChange={(e) => setOrganizer(e.currentTarget.value)}
                      placeholder="+ Event Organizer "
                      className="form-control"
                      required
                    />
                  </section>
                  <section className="form-group">
                    <label> Event Location </label>

                    <input
                      type="location"
                      value={location}
                      onChange={(e) => setLocation(e.currentTarget.value)}
                      placeholder="+ Event Location "
                      className="form-control"
                      required
                    />
                  </section>
                  <section className="form-group">
                    <label> Event Date </label>

                    <input
                      type="date"
                      value={event_date}
                      onChange={(e) => setEventDate(e.currentTarget.value)}
                      className="form-control"
                      required
                    />
                  </section>

                  <div className="row">
                    <div className="col-md-6">
                      <section className="form-group">
                        <label> Event Start Time </label>

                        <input
                          type="time"
                          value={start_time}
                          onChange={(e) => setStartTime(e.currentTarget.value)}
                          className="form-control"
                          required
                        />
                      </section>
                    </div>
                    <div className="col-md-6">
                      <section className="form-group">
                        <label> Event End Time </label>
                        <br />
                        <input
                          type="time"
                          value={end_time}
                          onChange={(e) => setEndTime(e.currentTarget.value)}
                          className="form-control"
                          required
                        />
                      </section>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-center mt-2">
                  <input
                    type="submit"
                    value="Submit"
                    disabled={loading}
                    className="btn btn-primary mr-1"
                  />

                  <Link to="/">
                    <button className="btn btn-secondary mr-1">Cancel</button>
                  </Link>
                </div>
              </form>
            </Card.Body>
          </Card>


        </div>
      </div>
    </div>
  );
};

export default EventDetails;
