import React from 'react';
import DeleteProfileDetails from '../../components/speakers/DeleteProfileDetails';

const DeleteProfileForm = () => {

  return (
    <div>
      <DeleteProfileDetails />
    </div>
  )
}
export default DeleteProfileForm;