import { IS_LOGGED_IN, CURRENT_USER } from '../../graphql/queries';
import { SIGNUP_USER } from '../../graphql/mutations';
import React, { useState } from 'react';
import { useHistory } from "react-router";
import { useMutation } from '@apollo/react-hooks';
import Alert from 'react-bootstrap/Alert';

const SignUpDetails = () => {
  const [email, setEmail] = useState("");
  const [fname, setFirstname] = useState("");
  const [lname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  let history = useHistory();
  const [signUpUser, { loading, error }] = useMutation(
    SIGNUP_USER,
    {
      variables: { email, fname, lname, password, phone, location },
      update(cache, { data: { signup } }) {
        if (!signup) setErrorMessage('Invalid Credentials');
        else {
          localStorage.setItem('token', signup.token);
        }
      },
      onError() {
        console.log("Error on Signup", error);
        setErrorMessage("Password must be more than 8 characters.");
      },
      refetchQueries: [{ query: IS_LOGGED_IN }, { query: CURRENT_USER }],
      onCompleted: (data) => {
        history.push("/speaker")
      }
    }
  )
  return (
              <form onSubmit={(e) => {
                e.preventDefault();
                signUpUser();
              }} >
    
                <div>
                  <section className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      value={fname}
                      onChange={(e) => {setFirstname(e.currentTarget.value)}}
                      placeholder="First Name (required)"
                      className="form-control"
                      required
                    />
                  </section>
                  <section className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      value={lname}
                      onChange={(e) => {setLastname(e.currentTarget.value)}}
                      placeholder="Last Name (required)"
                      className="form-control"
                      required
                    />
                  </section>
                  <section className="form-group">
                    <label>Phone Number</label>
                    <input
                      type="phone"
                      value={phone}
                      onChange={(e) => {setPhone(e.currentTarget.value)}}
                      placeholder="Phone Number (required)"
                      className="form-control"
                      required
                    />
                  </section>
                  <section className="form-group">
                    <label>Location</label>
                    <input
                      type="location"
                      value={location}
                      onChange={(e) => {setLocation(e.currentTarget.value)}}
                      placeholder="Location (required)"
                      className="form-control"
                      required
                    />
                  </section>
                  <section className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => {setEmail(e.currentTarget.value)}}
                      placeholder="Email (required)"
                      className="form-control"
                      required
                    />
                  </section>
                  <section className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => {setPassword(e.currentTarget.value)}}
                      placeholder="Password (required)"
                      className="form-control"
                      required
                    />
                  </section>
                  {

                    errorMessage ? <Alert variant="danger" className="text-center" fade={false}>! {errorMessage}</Alert> : ""
                  }
                  <div className="text-center">
                    <button type="submit" disabled={loading} className="btn  btn-success mb-3 form-control" > Sign Up </button>
                  </div>

                
                </div>

              </form>


          




  

 
 

  )
}

export default SignUpDetails;
