import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { CHANGE_PASSWORD } from "../../graphql/mutations";
import { CURRENT_USER, IS_LOGGED_IN } from "../../graphql/queries";
import Alert from 'react-bootstrap/Alert';

import { Link, useHistory } from "react-router-dom";

const PasswordChgDetails = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  let history = useHistory();

  const [
    changePassword,
    { loading, error },
  ] = useMutation(CHANGE_PASSWORD, {
    variables: { oldPassword, newPassword },
    onError() {
      setErrorMessage("Something went wrong", error);
    },
    refetchQueries: [{ query: IS_LOGGED_IN }, { query: CURRENT_USER }],
  });

  if (loading) return <h1> Loading...</h1>
  if (error) return <h1> Error </h1>

  return (
    <div className="container-fluid">
      <div
        className="row justify-content-center align-items-center "
        style={{ minHeight: "calc(100vh - 198px)" }}
      >
        <div className="col-md-8 mt-3 mb-3 ">
          <div className="card bg-pink-gradiant">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <img
                    src={
                      require("../../assets/images/login-illustration.svg")
                        .default
                    }
                    alt="loginIllustration"
                    className="d-block m-auto img-fluid"
                  />
                </div>
                <div className="col-md-6 p-5">
                  <form
                
                    onSubmit={(e) => {
                      e.preventDefault();
                      changePassword();
                      history.push("/");
                    }}
                  >
                    <h4 className="text-center mb-5">Change Password</h4>

                    <div className="form-group">
                      <label >Current Password</label>

                      <input
                        id="old-pwd"
                        type="password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.currentTarget.value)}
                        placeholder="Current Password"
                        className="form-control"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label >New Password</label>

                      <input
                        id="new-pwd"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.currentTarget.value)}
                        placeholder="New Password"
                        className="form-control"
                        required
                      />
                    </div>
                  {

                    errorMessage ? <Alert variant="danger" className="text-center" fade={false}>! {errorMessage}</Alert> : ""
                  }
                    <button type="submit" className="btn btn-primary mr-1">
                      Update Passwords
                    </button>

                    <Link to="/" className="btn btn-secondary">
                      Cancel
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordChgDetails;