import React from "react";
import { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { LOGIN_USER } from "../../graphql/mutations";
import Modal from "../../../node_modules/react-bootstrap/Modal";
import SignUpDetails from "../../components/session/SignUpDetails";
import Alert from "react-bootstrap/Alert";
import '../../styles/session/SignInSignUp.css';

const SignInSignUp = (props) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const [SignInState, setSignInState] = useState(true);
  const [SignUpState, setSignUpState] = useState(false);
  const onSignUpClick = () => {
    setSignInState(false);
    setSignUpState(true);
  };
  const onSignInClick = () => {
    setSignInState(true);
    setSignUpState(false);
  };

  const [loginUser, { loading, error }] = useMutation(LOGIN_USER, {
    variables: {
      email,
      password,
    },
    update(cache, { data: { login } }) {
      if (!login) setErrorMessage("Invalid Credentials");
      else {
        cache.writeData({ data: { isLoggedIn: login.loggedIn } });
        localStorage.setItem("token", login.token);
      }
    },
    onError() {
      setErrorMessage("Something Went Wrong", error);
    },
    // refetchQueries: [{ query: CURRENT_USER }, { query: IS_LOGGED_IN }]
  });

  return (
    <Modal
      {...props}
      size="sm modal-custom"
      aria-labelledby="contained-modal-title-vcenter"
      centered
     
    >
      <Modal.Header closeButton className="align-items-center">
        <Modal.Title id="contained-modal-title-vcenter">
          <img src={require("../../assets/images/SSBLogoPink.png").default} alt="SheSpeaksBureau"/>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="signIn"
          style={{ display: SignInState ? "block" : "none" }}
        >
          <div className="row mb-3">
            <div className="col-6">
              <h5>Sign In</h5>
            </div>
            <div className="col-6 text-right">
              <span
                className="btn btn-sm  btn-outline-primary rounded-pill border-w-2"
                onClick={onSignUpClick}
              >
                Sign Up
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  loginUser();
                }}
              >
                <section className="form-group">
                  <label htmlFor="email">Email</label>

                  <input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.currentTarget.value);
                      setErrorMessage("");
                    }}
                    className="form-control"
                    required
                  />
                </section>
                <section className="form-group">
                  <label htmlFor="password">Password</label>

                  <input
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.currentTarget.value);
                      setErrorMessage("");
                    }}
                    className="form-control"
                    required
                  />
                </section>
                {errorMessage ? (
                  <Alert variant="danger" className="text-center" fade={false}>
                    ! {errorMessage}
                  </Alert>
                ) : (
                  ""
                )}
                <div className="text-center">
                  <button
                    type="submit"
                    disabled={loading}
                    className=" btn  btn-success mb-3 form-control"
                  >
                    Sign In
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className="signUp"
          style={{ display: SignUpState ? "block" : "none" }}
        >
          <div className="row mb-3">
            <div className="col-6">
              <h5>Sign Up</h5>
            </div>
            <div className="col-6 text-right">
              <span
                className="btn btn-sm  btn-outline-primary rounded-pill border-w-2"
                onClick={onSignInClick}
              >
                Sign In
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <SignUpDetails />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SignInSignUp;
