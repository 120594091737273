//Error message in case the server is not responding
import React from "react";
import { Link } from "react-router-dom";
import "../../styles/error/ErrorPage.css";

function ErrorPage() {
  return (
    <div className="container-fluid">
      <div
        className="row justify-content-center align-items-center "
        style={{ minHeight: "calc(100vh - 198px)" }}
      >
        <div className="col-12 text-center">
        <h1>Page Not Found</h1>
        <p> The page you're looking for doesn't exist!</p>

        <Link to="/" className="back-to-home-link">
          Back to Home Page
        </Link>
        </div>
       
      </div>
    </div>
  );
}

export default ErrorPage;
