import React from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import NavDropdown from "../../../node_modules/react-bootstrap/NavDropdown";
import { useQuery } from '@apollo/react-hooks';
import { CURRENT_USER} from '../../graphql/queries';

const ProfileDropdown = () => {
  const client = useApolloClient();
  const { data, loading, error } = useQuery(CURRENT_USER);
  if (loading) return  "Loading...";
  if (error) return "Error";
  if (!data) return "No user found";
  const user = data.me;
  const profile_link = user.hasSpeakerProfile ? "/editProfile/" + user._id : "/speaker";
  const profile_label = user.hasSpeakerProfile ? "Edit Speaker Profile" : "Create Speaker Profile";
  // const delete_profile = user.hasSpeakerProfile ? "Remove Speaker Profile" : "";

  return (
    <NavDropdown
      title={<FontAwesomeIcon icon={faUserCircle} className="fa-2x" style={{ fontSize: "1.8em" }}/>}
      id="basic-nav-dropdown"
    >
      <NavDropdown.Item>
        <div className="font-weight-bold"><FontAwesomeIcon icon={faUserCircle} className="fa-lg mr-2" />{user.fname} {user.lname} </div>
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item href={profile_link}>{profile_label}</NavDropdown.Item>
      <NavDropdown.Item href='/event'> Add an Event </NavDropdown.Item>
      <NavDropdown.Item href="/password">Change Password</NavDropdown.Item>
      {/* <NavDropdown.Item href="/remove">{delete_profile}</NavDropdown.Item> */}
     
      <NavDropdown.Divider />
      <NavDropdown.Item
        onClick={() => {
          client.resetStore();
        }}
      >
        <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" /> Sign out
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default ProfileDropdown;
