import gql from 'graphql-tag'

export const USER_CREDENTIALS_DATA = gql`
  fragment UserCredentialsData on UserCredentials {
    _id
    email
    fname
    lname
    phone
    location
    token
    loggedIn
    hasSpeakerProfile
  }
`;

export const SPEAKER_DATA = gql`
  fragment Speaker on SpeakerResponse {
    speaker{
      _id
      fname
      lname
      handle
      email
      phone
      location
      org
      role
      bio
      image
      topics
      tags {
        _id
        hashtag
        subjects
      }
      languages
      exp_level
      linkedIn
      twitter
      instagram
      facebook
      otherLinks
      fee 
      featured,
      is_active,
      order
    }
  }
`;

export const EVENT_DATA = gql`
  fragment Event on EventResponse {
    event {
      _id
      name
      link
      desc
      organizer
      location
      event_date
      start_time
      end_time
      priority
      is_active
    }
  }
`;

export const BOOKING_DATA = gql`
  fragment Booking on BookingResponse {
    booking{
      _id
      requestor {
        _id
        name
      }
      event {
        _id
        name
        event_date
      }
      speaker {
        _id
        name
      }
      date
   }
  }
`;

export const TAG_DATA = gql`
  fragment TagData on TagResponse {
    tag {
      _id
      hashtag
      subjects
    }
  }
  `;
