import gql from 'graphql-tag';

export const CURRENT_USER = gql`
  query CurrentUser {
    me {
      _id
      email
      fname
      lname
      phone
      location
      hasSpeakerProfile
    }
  }
`;

export const IS_LOGGED_IN = gql`
  query IsLoggedIn {
    isLoggedIn @client
  }
`;

export const FETCH_SPEAKER_BY_HANDLE = gql`
  query speakerByHandle($handle: String) {
    speakerByHandle(handle: $handle) {
      _id
      fname
      lname
      handle
      location
      org
      role
      bio
      image
      topics
      tags {
        _id
        hashtag
        subjects
      }
      languages
      exp_level
      linkedIn
      twitter
      instagram
      facebook
      otherLinks
      videoLinks
      fee
    }
  }
`;

export const FETCH_SPEAKERS = gql`
  query fetchSpeakers {
    speakers {
      _id
      fname
      lname
      featured
      handle
      location
      org
      role
      bio
      image
      topics
      tags {
        _id
        hashtag
        subjects
      }
      languages
      exp_level
      linkedIn
      twitter
      instagram
      facebook
      otherLinks
      videoLinks
      fee
      is_active
      order
    }
  }
`;

export const FETCH_SPEAKER_BY_USERID = gql`
  query speakerByUserId($userId: ID) {
    speakerByUserId(userId: $userId) {
      _id
      fname
      lname
      handle
      location
      org
      role
      bio
      image
      topics
      tags {
        _id
        hashtag
        subjects
      }
      languages
      exp_level
      linkedIn
      twitter
      instagram
      facebook
      otherLinks
      videoLinks
      fee
      is_active
      order
    }
  }
`;

export const FETCH_SPEAKERS_AND_TAGS = gql`
  query fetchSpeakersAndTags {
    speakers {
      _id
      fname
      lname
      featured
      handle
      location
      org
      role
      bio
      image
      topics
      tags {
        _id
        hashtag
        subjects
      }
      languages
      exp_level
      linkedIn
      twitter
      instagram
      facebook
      otherLinks
      videoLinks
      fee
      is_active
      order
    }
    tags{
      _id
      hashtag
      subjects
    }
  }
`;

export const FETCH_TAGS = gql`
  query fetchTags {
    tags{
      _id
      hashtag
      subjects
    }
  }
`;

export const FETCH_BOOKINGS = gql `
  query fetchBookings {
    bookings {
      requestor {
        _id
        fname
        lname
      }
      event {
        _id
        name
      }
      speaker {
        _id
        fname
        lname
      }
    }
  }
`;

export const FETCH_EVENTS = gql `
  query fetchEvents {
    events {
      _id
      name
      link
      desc
      organizer
      location
      event_date
      start_time
      end_time
      priority
      is_active
    }
  }
`;