import React from "react";
import EventsPageDetails from "../../components/events/EventsPageDetails";

const EventsPage = () => {
  return (
    <main style={{ minHeight: "calc(100vh - 198px)" }}>
      <EventsPageDetails />
    </main>
  );
};

export default EventsPage;