import React from 'react';
import BookingDetails from '../../components/bookings/BookingDetails';
import '../../styles/bookings/BookingPage.css';

function BookingForm () {

  return (

          <BookingDetails />
    
  )
}
export default BookingForm;