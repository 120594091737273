import React from 'react';
import LogInDetails from '../../components/session/LogInDetails';

const LogInForm = () => {
  return (
    <div>
      <LogInDetails/>
    </div>
  );
};

export default LogInForm;