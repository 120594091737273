import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import ReactPlayer from "react-player";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { useQuery } from "@apollo/react-hooks";
import { FETCH_SPEAKER_BY_HANDLE } from "../../graphql/queries";
import Loader from "../../components/loader/Loader";
import "../../styles/speakers/SpeakerProfilePage.css";

const SpeakerProfileDetails = (props) => {
  const selectedSpeakerHandle = props.selectedSpeakerHandle;

  const { loading, error, data } = useQuery(FETCH_SPEAKER_BY_HANDLE, {
    variables: { handle: selectedSpeakerHandle },
  });

  if (loading) return <Loader />;
  if (error) return <h1> Error Loading Speaker Profile</h1>;
  if (!data) return <h1> No Speaker found </h1>;
  const speaker = data.speakerByHandle;
  const name = speaker.fname + " " + speaker.lname;
  const speakerExplevel = {
    1: "Professional Speaker (Paid Engagements)",
    2: "Experienced Speaker",
    3: "Aspiring Speaker",
  };

  // console.log(speaker);

  return (
    <div className="container pt-3 pb-3">
      <div className="row">
        <div className="col-md-4">
          <div className="card  profile-card-bg">
            <div className="card-body">
              <div className="mt-5">
                <img
                  src={speaker.image}
                  // data-blink-src={speaker.image}
                  className="img-thumbnail rounded-circle mx-auto d-block"
                  alt="avatar"
                />
              </div>
              <div className="profile-social-icons-wrap">
                {speaker.twitter !== "" && speaker.twitter ? (
                  <a
                    href={speaker.twitter}
                    target="_blank"
                    rel="noreferrer"
                    className="profile-social-icon"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                ) : (
                  <a
                    rel="noreferrer"
                    className="profile-social-icon disabled-link"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                )}

                {speaker.facebook !== "" && speaker.facebook ? (
                  <a
                    href={speaker.facebook}
                    target="_blank"
                    rel="noreferrer"
                    className="profile-social-icon "
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                ) : (
                  <a
                    rel="noreferrer"
                    className="profile-social-icon disabled-link"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                )}

                {speaker.instagram !== "" && speaker.instagram ? (
                  <a
                    href={speaker.instagram}
                    target="_blank"
                    rel="noreferrer"
                    className="profile-social-icon"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                ) : (
                  <a
                    rel="noreferrer"
                    className="profile-social-icon  disabled-link"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                )}

                {speaker.linkedIn !== "" && speaker.linkedIn ? (
                  <a
                    href={speaker.linkedIn}
                    target="_blank"
                    rel="noreferrer"
                    className="profile-social-icon"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                ) : (
                  <a
                    rel="noreferrer"
                    className="profile-social-icon disabled-link"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                )}
              </div>

              <div className="text-center">
                <h4 className="card-title  text-dark mb-2">{name}</h4>

                <span className="text-muted">{speaker.role}</span>

                <p className="text-primary">{speaker.org}</p>
              </div>
            </div>

            <div>
              <ListGroup variant="flush" className="p-2">
                <ListGroup.Item>
                  <div className="text-secondary font-weight-bold mb-1">
                    Location
                  </div>
                  <span className="text-muted">{speaker.location}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="text-secondary font-weight-bold mb-1">
                    Language(s)
                  </div>
                  <span className="text-muted">{speaker.languages}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="text-secondary font-weight-bold mb-1">
                    Experience
                  </div>
                  <span className="text-muted">
                    {
                      speakerExplevel[speaker.exp_level]
                    }
                  </span>
                </ListGroup.Item>

              </ListGroup>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <Card className="mb-3">
            <Card.Header>
              <div className="font-weight-bold">Biography </div>
            </Card.Header>
            <Card.Body>
              <Card.Text className="text-muted" style={{whiteSpace: "pre-wrap"}}>{speaker.bio}</Card.Text>
            </Card.Body>
          </Card>
          {speaker.otherLinks !== undefined && speaker.otherLinks.length > 0 && (
            <Card className="mb-3">
              <Card.Header>
                <div className="font-weight-bold">Other Links </div>
              </Card.Header>
              <Card.Body>
                <ul>
                  {speaker.otherLinks.map((otherlink, index) => (
                    <li key={index}>
                      <a href={otherlink} target="_blank" rel="noreferrer">
                        {otherlink}
                      </a>
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          )}

          {speaker.videoLinks !== undefined && speaker.videoLinks.length > 0 && (
            <Card className="mb-3">
              <Card.Header>
                <div className="font-weight-bold">Video Links </div>
              </Card.Header>
              <Card.Body>
                <div className="row">
                  {speaker.videoLinks.map((videoLinks, index) => (
                    <div className="col-md-6 mb-3" key={index}>
                      <ReactPlayer
                        url={videoLinks}
                        controls= {true}
                        loop={false}
                        light={false}
                        width="100%"
                        height="200px"
                      />
                    </div>
                  ))}
                </div>
              </Card.Body>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpeakerProfileDetails;
