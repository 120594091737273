import React from "react";
import Spinner from "../../../node_modules/react-bootstrap/Spinner";
const Loader = () => {
  return (
    <div className="container-fluid">
      <div
        className="row justify-content-center align-items-center "
        style={{ minHeight: "calc(100vh - 198px)" }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    </div>
  );
};

export default Loader;