import gql from 'graphql-tag';
import { USER_CREDENTIALS_DATA, SPEAKER_DATA, EVENT_DATA, BOOKING_DATA, TAG_DATA } from './fragments';

export const SIGNUP_USER = gql`
  mutation SignUp($email: String!, $fname: String!, $lname: String!, $password: String!, $phone: String!, $location: String!) {
    signup(email: $email, fname: $fname, lname: $lname, password: $password, phone: $phone, location: $location) {
      ...UserCredentialsData
    }
  }
  ${USER_CREDENTIALS_DATA}
`;

export const LOGIN_USER = gql`
  mutation LogIn($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ...UserCredentialsData
    }
  }
  ${USER_CREDENTIALS_DATA}
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      ...UserCredentialsData
    }
  }
  ${USER_CREDENTIALS_DATA}
`;

export const ADD_SPEAKER = gql`
  mutation addSpeaker($org: String, $role: String, $bio: String, $image: String, $topics: [String], $tags: [ID], $languages: String, $exp_level: Int, $linkedIn: String, $twitter: String, $instagram: String, $facebook: String, $otherLinks: [String], $videoLinks: [String], $fee: Float){
    addSpeaker(org: $org, role: $role, bio: $bio, image: $image, topics: $topics, tags: $tags, languages: $languages, exp_level: $exp_level, linkedIn: $linkedIn, twitter: $twitter, instagram: $instagram, facebook: $facebook, otherLinks: $otherLinks,videoLinks: $videoLinks,  fee: $fee){
      ...Speaker
    }
  }
  ${SPEAKER_DATA}
`;

export const UPDATE_SPEAKER = gql`
  mutation updateSpeaker($speakerId: ID, $org: String, $role: String, $bio: String, $image: String, $topics: [String], $tags: [ID], $languages: String, $exp_level: Int, $linkedIn: String, $twitter: String, $instagram: String, $facebook: String, $otherLinks: [String], $videoLinks: [String], $fee: Float){
    updateSpeaker(speakerId: $speakerId, org: $org, role: $role, bio: $bio, image: $image, topics: $topics, tags: $tags, languages: $languages, exp_level: $exp_level, linkedIn: $linkedIn, twitter: $twitter, instagram: $instagram, facebook: $facebook, otherLinks: $otherLinks,videoLinks: $videoLinks, fee: $fee){
      ...Speaker
    }
  }
  ${SPEAKER_DATA}
`;

export const ADD_EVENT = gql `
  mutation addEvent($name: String!, $link: String!, $desc: String!, $organizer: String!, $location: String!, $event_date: Date!, $start_time: String!, $end_time: String!) {
    addEvent(name: $name, link: $link, desc: $desc, organizer: $organizer, location: $location, event_date: $event_date, start_time: $start_time, end_time: $end_time){
      ...Event
    }
  }
  ${EVENT_DATA}
`;

export const ADD_BOOKING = gql `
  mutation addBooking($eventId: ID!, $speakerId: ID!){
    addBooking(eventId: $eventId, speakerId: $speakerId){
      ...Booking
    }
  }
  ${BOOKING_DATA}
`;

export const ADD_TAG= gql`
  mutation AddTag( $hashtag: String!, $subjects: [String] ) {
    addTag( hashtag: $hashtag, subjects: $subjects, speakerId: $speakerId ) {
      ...TagData
    }
  }
  ${TAG_DATA}
`;

export const REMOVE_TAG= gql`
  mutation RemoveTag( $tagId: ID!, $speakerId: ID! ) {
    removeTag( tagId: $tagId, speakerId: $speakerId ) {
      ...TagData
    }
  }
  ${TAG_DATA}
`;

export const DELETE_SPEAKER = gql`
  mutation deleeSpeaker( $speakerId: ID ){
    deleteSpeaker( speakerId: $speakerId ){
      ...Speaker
    }
  }
  ${SPEAKER_DATA}
`;