import React from 'react';
import { FETCH_TAGS } from '../../graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import { Multiselect } from 'multiselect-react-dropdown';

const TagList = ({speaker, setTags}) => {
  const { data, loading, error } = useQuery( FETCH_TAGS );
  let tags = data ? data.tags : [""];

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  const onSelect = (selectedList, selectedItem) => {
    console.log("Added Tag", selectedItem);

    const selectedTags = selectedList.map(tag => tag._id);
    setTags(selectedTags);
  }

  const onRemove = (selectedList, removedItem) => {
    console.log("Removed Tag", removedItem);

    const selectedTags = selectedList.map(tag => tag._id);
    setTags(selectedTags);
  }

  return (
    <Multiselect
      options={tags} // Options to display in the dropdown
      selectedValues={speaker.tags} // Preselected value to persist in dropdown
      onSelect={onSelect} // Function will trigger on select event
      onRemove={onRemove} // Function will trigger on remove event
      displayValue="hashtag" // Property name to display in the dropdown options

    />
  );
}

export default TagList;
