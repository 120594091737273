import React from 'react';
import SpeakerListDetails from '../../components/speakers/SpeakersListDetails';
import { FETCH_SPEAKERS_AND_TAGS } from "../../graphql/queries";
import { useQuery } from "@apollo/react-hooks";
import Loader from "../../components/loader/Loader";

import '../../styles/speakers/SpeakersPage.css';

const SpeakersPage = () => {
  const { loading, error, data } = useQuery(FETCH_SPEAKERS_AND_TAGS);

  if (loading) return <Loader />;
  if (error) return `Error! ${error.message}`;

  const {speakers, tags} = data;
  return (
    <main style={{ minHeight: "calc(100vh - 198px)" }}>      
     
        <SpeakerListDetails speakers={speakers} tags={tags}/>
      
    </main>
  )
}
export default SpeakersPage;