import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_SPEAKER } from "../../graphql/mutations";
import { CURRENT_USER, IS_LOGGED_IN } from "../../graphql/queries";
import Alert from 'react-bootstrap/Alert';

import { Link, useHistory } from "react-router-dom";

const DeleteProfileDetails = () => {
  const [errorMessage, setErrorMessage] = useState("");

  let history = useHistory();

  const [
    deleteProfile,
    { loading, error },
  ] = useMutation(DELETE_SPEAKER, {
    onError() {
      setErrorMessage("Failed to Delete Profile, please contact administrator", error);
    },
    refetchQueries: [{ query: IS_LOGGED_IN }, { query: CURRENT_USER }],
  });

  if (loading) return <h1> Loading...</h1>

  return (
    <div className="container-fluid">
      <div
        className="row justify-content-center align-items-center "
        style={{ minHeight: "calc(100vh - 198px)" }}
      >
        <div className="col-md-8 mt-3 mb-3 ">
          <div className="card bg-pink-gradiant">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <p> We hate to let let you go! Female Speakers are a minority, Please update your profile if you want to change how you represent yourself! </p>
                </div>
                <div className="col-md-6 p-5">
                  <form
                
                    onSubmit={(e) => {
                      e.preventDefault();
                      deleteProfile();
                      history.push("/");
                    }}
                  >
                  <h4 className="text-center mb-5">Delete Profile</h4>
                  {

                    errorMessage ? <Alert variant="danger" className="text-center" fade={false}>! {errorMessage}</Alert> : ""
                  }
                    <button type="submit" className="btn btn-primary mr-1">
                      Delete Profile
                    </button>

                    <Link to="/" className="btn btn-secondary">
                      Cancel
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteProfileDetails;