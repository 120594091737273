import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarWeek, faClock } from "@fortawesome/free-solid-svg-icons";
import "../../styles/events/EventsPage.css";

const EventsListItem = (props) => {
  const event = props.event;
  const date = new Date(event.event_date).toLocaleDateString();

  return (
    <div className="card card-body h-100">
      <span className="side-stick"></span>
      <h5 className="text-truncate">
        <a
          href={event.link}
          target="_blank"
          rel="noreferrer"
        >
          {event.name}
        </a>
      </h5>
      <div className="row">
        <div className="col ">
          <p className="text-primary mb-1">{event.location}</p>
        </div>

        <div className="col-md-auto  mb-2 ">
          <span className="font-weight-bold  text-secondary">
            <FontAwesomeIcon
              icon={faCalendarWeek}
              className="mr-2 text-primary"
            />
            Event Date :
          </span>
          <span className=" text-muted pl-2">{date}</span>
        </div>
        <div className="col-md-auto  mb-2 ">
          <span className="font-weight-bold  text-secondary">
            <FontAwesomeIcon icon={faClock} className="mr-2 text-primary" />
            Start - End Time :
          </span>
          <span className=" text-muted pl-2">
            {event.start_time} - {event.end_time}
          </span>
        </div>
      </div>

      {/* <div className="row">
        <div class="col-md-12 mb-2">
        <span class="badge badge-light">Organized by : Organizer  name</span>
        </div>
      </div> */}

      <p className="text-muted event-tile-desc">{event.desc}</p>

      {/* <div className="row">
        <div className="col-12">
          <a href="#" className="btn btn-sm btn-primary" target="_blank">Read More..</a>
        </div>
      </div> */}
    </div>
  );
};

export default EventsListItem;
