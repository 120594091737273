import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from "@apollo/react-hooks";
import { FETCH_SPEAKER_BY_USERID } from "../../graphql/queries";
import Loader from "../../components/loader/Loader";
import EditSpeakerDetails from '../../components/speakers/EditSpeakerDetails';
import '../../styles/speakers/SpeakerPage.css';

const EditSpeakerForm = () => {
  const params = useParams();
  const loggedUserID = params.userId;

  const { loading, error, data } = useQuery(FETCH_SPEAKER_BY_USERID, {
    variables: { userId: loggedUserID },
  });

  if (loading) return <Loader />;
  if (error) return <h1> Error Loading Speaker Profile</h1>;
  if (!data) return <h1> No Speaker found </h1>;
  const speaker = data.speakerByUserId;

  return (
    <main className="add_speaker_main">      
      <section className="add_speaker_body">
        <div className="speaker_details">
          <EditSpeakerDetails speaker={speaker}  />
        </div>
      </section>
    </main>
  )
}
export default EditSpeakerForm;