import React, { useState } from "react";
import { useHistory } from "react-router";
import Card from "react-bootstrap/Card";

import { ADD_SPEAKER } from "../../graphql/mutations";
import { Widget } from "@uploadcare/react-widget";
import { UPLOADCARE_PUBLIC_KEY } from "../../config/keys";
import { useMutation } from "@apollo/react-hooks";
import { CURRENT_USER } from "../../graphql/queries";
import NewTagsList from "../tags/NewTagsList";

import "../../styles/speakers/SpeakerPage.css";

const AddSpeakerDetails = () => {
  const [org, setOrg] = useState("");
  const [role, setRole] = useState("");
  const [bio, setBio] = useState("");
  const [image, setImage] = useState(null);
  const [topics, setTopics] = useState([""]);
  const [tags, setTags] = useState([""]);
  const [languages, setLanguages] = useState("");
  const [exp_level, setExpLevel] = useState(3);
  const [linkedIn, setLinkedIn] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [otherLinks, setOtherLinks] = useState([""]);
  const [videoLinks, setVideoLinks] = useState([""]);
  const [fee, setFee] = useState(0.0);
 

  // Check if list objects are null
  if (otherLinks.length === 0) setOtherLinks([""]);
  if (videoLinks.length === 0) setVideoLinks([""]);
  if (topics.length === 0) setTopics([""]);

  // handle input change for all list fields
  // other links, video links, topics
  const handleInputChange = (e, index) => {

    const { value } = e.target;

    if (e.target.name === "eventLink") {
      const linksList = [...otherLinks];
      linksList[index] = value;
      setOtherLinks(linksList);
    }
    if (e.target.name === "videoLink") {
      const videoList = [...videoLinks];
      videoList[index] = value;
      setVideoLinks(videoList);
    }
    if (e.target.name === "topics") {
      const topicList = [...topics];
      topicList[index] = value;
      setTopics(topicList);
    }

  };

  // handle click event of the Remove button
  const handleRemoveClick = (e, index) => {

    if (e.target.name === "eventLinkDelete") {
      const linksList = [...otherLinks];
      e.preventDefault();
      linksList.splice(index, 1);
      setOtherLinks(linksList);
    }
    if (e.target.name === "videoLinkDelete") {
      const videoList = [...videoLinks];
      e.preventDefault();
      videoList.splice(index, 1);
      setVideoLinks(videoList);
    }
    if (e.target.name === "topicDelete") {
      const topicList = [...topics];
      e.preventDefault();
      topicList.splice(index, 1);
      setTopics(topicList);
    }
  };

  // handle click event of the Add buttonss
  const handleAddClick = (e) => {
    // console.log(e.target.name);
    if (e.target.name === "eventLinkAdd") setOtherLinks([...otherLinks, ""]);

    if (e.target.name === "videoLinkAdd")  setVideoLinks([...videoLinks, ""]);

    if (e.target.name === "topicAdd") setTopics([...topics, ""]);

  };

  // Save the Created Profile, update db, and redirect to profile page with the updated store
  let history = useHistory();
  const [addSpeaker, { loading, error }] = useMutation(ADD_SPEAKER, {
    variables: {
      org: org,
      role: role,
      bio: bio,
      image: image,
      topics: topics.filter((entry) => entry.trim() !== ""),
      tags: tags,
      languages: languages,
      exp_level: parseInt(exp_level),
      linkedIn: linkedIn,
      twitter: twitter,
      instagram: instagram,
      facebook: facebook,
      otherLinks: otherLinks.filter((entry) => entry.trim() !== ""),
      videoLinks: videoLinks.filter((entry) => entry.trim() !== ""),
      fee: parseFloat(fee),
    },
    onError() {
      console.log("Error creating Speaker", error);
      throw Error("Error creating Speaker Profile", error);
    },
    refetchQueries: [{ query: CURRENT_USER }],
    onCompleted: (data) =>
      history.push("/speaker/" + data.addSpeaker.speaker.handle),
  });

  return (
    <Card className="mt-2 mb-2 mr-auto ml-auto" style={{ maxWidth: "800px" }}>
      <Card.Header>
        <h4 className="mb-0 text-center">Create your Speaker Profile</h4>{" "}
      </Card.Header>
      <Card.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            addSpeaker();
          }}
        >
          <div>
            <div className="row">
              <section className="col-md-6">
                <div className="form-group">
                  <label>Employer</label>

                  <input
                    type="text"
                    value={org}
                    placeholder="+ Add Organization"
                    className="form-control"
                    onChange={(e) => setOrg(e.currentTarget.value)}
                    required
                  />
                </div>
              </section>
              <section className="col-md-6">
                <div className="form-group">
                  <label>Role</label>
                  <input
                    type="text"
                    value={role}
                    placeholder="+ Add Role"
                    className="form-control"
                    onChange={(e) => setRole(e.currentTarget.value)}
                    required
                  />
                </div>
              </section>
            </div>

            <div className="row">
              <section className="col-md-12">
                <div className="form-group">
                  <label>
                    Speaker Profile
                    <small className="d-block text-muted">
                      Please provide details of background - professional,
                      personal and speaking related as appropriate for Event
                      Organizers
                    </small>
                  </label>

                  <textarea
                    rows="4"
                    value={bio}
                    placeholder="+ Add Biography"
                    className="form-control"
                    onChange={(e) => setBio(e.currentTarget.value)}
                    required
                  />
                </div>
              </section>
            </div>
            <div className="row">
              <section className="col-md-12">
                <div className="form-group">
                  <label>Attach your Profile Image </label>
                  <div>
                    <Widget
                      publicKey={UPLOADCARE_PUBLIC_KEY}
                      onChange={(info) =>
                        setImage(info.cdnUrl + "-/scale_crop/1024x1024/smart/")
                      }
                    />
                  </div>
                </div>
              </section>
            </div>
                        <div className="row">
              <section className="col-md-12">
                <div className="form-group">
                  <label>Topics covered by the speaker</label>

                  {topics && topics.map((x, i) => {
                      return (
                        <div className="form-group" key={i}>
                          <div className="row">
                            <div className="col">
                              <input
                                name="topics"
                                placeholder="+ Add Your Topics"
                                value={x}
                                onChange={(e) => handleInputChange(e, i)}
                                className="form-control"
                              />
                            </div>

                            {topics.length !== 1 && (
                              <div className="col-auto">
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => handleRemoveClick(e, i)}
                                  title="Delete Topic"
                                  name="topicDelete"
                                >
                                  X
                                </button>
                              </div>
                            )}
                          </div>

                          {topics.length - 1 === i && (
                            <div className="row mt-2">
                              <div className="col-12 ">
                                <button
                                  className="btn btn-primary"
                                  name="topicAdd"
                                  onClick={(e) => handleAddClick(e)}
                                >
                                  Add More Topics
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </section>
            </div>
            <div className="row">
              <section className="col-md-12">
                <div className="form-group">
                  <label>Tags </label>
                  <NewTagsList setTags={setTags} />
                </div>
              </section>
            </div> 
            <div className="row">
              <section className="col-md-12">
                <div className="form-group">
                  <label>Languages</label>
                  <input
                    type="text"
                    value={languages}
                    placeholder="+ Languages"
                    className="form-control"
                    onChange={(e) => setLanguages(e.currentTarget.value)}
                  />
                </div>
              </section>
            </div>

            <div className="row">
              <section className="col-md-6">
                <div className="form-group">
                  <label>Your Speaker experience Level</label>
                  <select
                    value={exp_level}
                    onChange={(e) => setExpLevel(e.currentTarget.value)}
                    className="form-control"
                  >
                    <option value="1">
                      Professional Speaker (Paid Engagements)
                    </option>
                    <option value="2">Experienced Speaker</option>
                    <option value="3">Aspiring Speaker</option>
                  </select>
                </div>
              </section>

              <section className="col-md-6">
                <div className="form-group">
                  <label>Fee</label>
                  <input
                    type="number"
                    value={fee}
                    placeholder="+ Add Fee"
                    className="form-control"
                    onChange={(e) => setFee(e.currentTarget.value)}
                  />
                </div>
              </section>
            </div>

            <div className="row">
              <section className="col-md-12">
                <div className="form-group">
                  <label>LinkedIn Profile</label>
                  <input
                    type="text"
                    value={linkedIn}
                    placeholder="+ Add LinkedIn Profile"
                    className="form-control"
                    onChange={(e) => setLinkedIn(e.currentTarget.value)}
                    required
                  />
                </div>
              </section>
            </div>

            <div className="row">
              <section className="col-md-12">
                <div className="form-group">
                  <label>Twitter Handle</label>
                  <br />
                  <input
                    type="text"
                    value={twitter}
                    placeholder="+ Add Twitter Handle"
                    className="form-control"
                    onChange={(e) => setTwitter(e.currentTarget.value)}
                  />
                </div>
              </section>
            </div>
            <div className="row">
              <section className="col-md-12">
                <div className="form-group">
                  <label>Instagram Handle</label>

                  <input
                    type="text"
                    value={instagram}
                    placeholder="+ Add Instagram Handle"
                    className="form-control"
                    onChange={(e) => setInstagram(e.currentTarget.value)}
                  />
                </div>
              </section>
            </div>

            <div className="row">
              <section className="col-md-12">
                <div className="form-group">
                  <label>Facebook Profile</label>
                  <input
                    type="text"
                    value={facebook}
                    placeholder="+ Add Facebook Profile"
                    className="form-control"
                    onChange={(e) => setFacebook(e.currentTarget.value)}
                  />
                </div>
              </section>
            </div>
            

            <div className="row">
              <section className="col-md-12">
                <div className="form-group">
                  <label>Links to previous speaking engagements</label>
                  {otherLinks &&
                    otherLinks.map((x, i) => {
                      return (
                        <div className="form-group" key={i}>
                          <div className="row">
                            <div className="col">
                              <input
                                name="eventLink"
                                placeholder="+ Add Link"
                                value={x}
                                onChange={(e) => handleInputChange(e, i)}
                                className="form-control"
                                contenttype="otherLinks"
                              />
                            </div>

                            {otherLinks.length !== 1 && (
                              <div className="col-auto">
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => handleRemoveClick(e, i)}
                                  title="Delete Link"
                                  name="eventLinkDelete"
                                >
                                  X
                                </button>
                              </div>
                            )}
                          </div>

                          {otherLinks.length - 1 === i && (
                            <div className="row mt-2">
                              <div className="col-12 ">
                                <button
                                  className="btn btn-primary"
                                  name="eventLinkAdd"
                                  onClick={(e) => handleAddClick(e)}
                                >
                                  Add More Links
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </section>
            </div>
            <div className="row">
              <section className="col-md-12">
                <div className="form-group">
                  <label>Links to Youtube video</label>

             

                  {videoLinks &&
                    videoLinks.map((x, i) => {
                      return (
                        <div className="form-group" key={i}>
                          <div className="row">
                            <div className="col">
                              <input
                                name="videoLink"
                                placeholder="+ Add Link"
                                value={x}
                                onChange={(e) => handleInputChange(e, i)}
                                className="form-control"
                                
                              />
                            </div>

                            {videoLinks.length !== 1 && (
                              <div className="col-auto">
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => handleRemoveClick(e, i)}
                                  title="Delete Link"
                                  name="videoLinkDelete"
                                >
                                  X
                                </button>
                              </div>
                            )}
                          </div>

                          {videoLinks.length - 1 === i && (
                            <div className="row mt-2">
                              <div className="col-12 ">
                                <button
                                  className="btn btn-primary"
                                  name="videoLinkAdd"
                                  onClick={(e) => handleAddClick(e)}
                                >
                                  Add More Links
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </section>
            </div>


            <div className="row">
              <section className="col-md-12 text-center">
                <input
                  type="submit"
                  value="SUBMIT"
                  disabled={loading}
                  className="btn btn-success"
                />
              </section>
            </div>
          </div>
        </form>
      </Card.Body>
    </Card>
  );
};

export default AddSpeakerDetails;
