import React from "react";
import "./footer.css";

const Footer = () => {
    return (

        <footer className="section footer-classic context-dark bg-image" style={{"background": "#2d3246"}}>
            <div className="container">
                <div className="row ">
                    <div className="col-md-12 mt-3 mb-1 text-center">
                        <div className="pr-xl-4">


                            <p className="rights"><span>©  </span><span className="copyright-year">2021</span><span> </span><span>She Speaks
                Bureau</span><span>. </span><span>All
                Rights Reserved.</span></p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row no-gutters social-container">
                <div className="col"><a className="social-inner" href="https://www.facebook.com/SheSpeaksBureau" target="_blank" rel="noreferrer" ><span
                    className="icon mdi mdi-facebook"></span><span>Facebook</span></a></div>
                <div className="col"><a className="social-inner" href="https://www.instagram.com/shespeaksbureau/" target="_blank" rel="noreferrer" ><span
                    className="icon mdi mdi-instagram"></span><span>Instagram</span></a></div>
                <div className="col"><a className="social-inner" href="https://twitter.com/shespeaksbureau"  target="_blank" rel="noreferrer" ><span
                    className="icon mdi mdi-twitter"></span><span>Twitter</span></a></div>
                <div className="col"><a className="social-inner" href="https://www.linkedin.com/company/she-speaks-bureau" target="_blank" rel="noreferrer" ><span className="icon mdi mdi-youtube-play"></span><span>LinkedIn
          </span></a></div>
            </div>
        </footer>
    )
}

export default Footer;