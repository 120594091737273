import React from 'react';
import { useParams } from 'react-router-dom';
import SpeakerProfileDetails from '../../components/speakers/SpeakerProfileDetails';
import '../../styles/speakers/SpeakerProfilePage.css';

const SpeakerProfile = () => {
  const params = useParams();
  const selectedSpeakerHandle = params.handle;


  return (
    <div style={{ minHeight: "calc(100vh - 198px)" }}>
      <SpeakerProfileDetails selectedSpeakerHandle={selectedSpeakerHandle} />
    </div>
  )
}
export default SpeakerProfile;