import React, { useState } from "react";
import Fuse from "fuse.js";
// import { FETCH_SPEAKERS_AND_TOPICS } from "../../graphql/queries";
// import { useQuery } from "@apollo/react-hooks";
import "react-widgets/dist/css/react-widgets.css";
// import Multiselect from "react-widgets/lib/Multiselect";
import SpeakersListItem from "./SpeakersListItem";
// import Loader from "../../components/loader/Loader";
import "../../styles/speakers/SpeakersPage.css";

const SpeakerListDetails = ({speakers, tags}) => {
  const [speakerList, setSpeakerList] = useState(speakers);

  const handleChange = (pattern) => {
    if (!pattern) {
      setSpeakerList(speakers);
      return;
    }
    const fuse = new Fuse(speakerList, {
      keys: ["fname", "lname", "role"]
    });

    const result = fuse.search(pattern);

    const matches = [];
    if (result) {
      result.forEach(({item}) => {  matches.push(item); });
    }

    setSpeakerList(matches);
  }


  return (
    <div>
      <div className="speaker-filter-section h-100 pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Search</label>
                <input
                  placeholder="Search by name, role"
                  onChange={(e) => handleChange(e.target.value, 1)} 
                  className="form-control"
                />
              </div>
            </div>
            {/* <div className="col-md-4">
              <div className="form-group">
                <label>Tags</label>
                <select
                  className="form-control"
                  onChange={(e) => handleChange(e.target.value, 2)}
                >
                  <option value="all">All</option>
                  {tags &&
                    tags.map((tag, i) => (
                      <option key={i} value={tag.hashtag}>
                        {tag.hashtag}
                      </option>
                    ))}
                </select>

                {/* <Multiselect data={tags.detail} placeholder="Choose subjects" /> */}
              {/* </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Location</label>
                <select
                  className="form-control"
                  onChange={(e) => handleChange(e.target.value, 3)}
                >
                  <option value="all">All</option>
                  {speakers &&
                    speakers.map((speaker, i) => (
                      <option key={i} value={speaker.location}>
                        {speaker.location}
                      </option>
                    ))}
                </select>
              </div>
            </div> */} 
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row pt-3 pb-3">
          {speakerList &&
            speakerList.map((speaker, i) => (
              <div className="col-md-6 mb-4" key={i}>
                <SpeakersListItem speaker={speaker} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SpeakerListDetails;
