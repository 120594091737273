import React from "react";
import { FETCH_SPEAKERS } from "../../graphql/queries";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import "react-widgets/dist/css/react-widgets.css";
import Loader from "../../components/loader/Loader";
import "../../styles/homepage/HomePage.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";

const HomePageDetails = () => {
  const { loading, error, data } = useQuery(FETCH_SPEAKERS);

  if (loading) return <Loader />;
  if (error) return `Error! ${error.message}`;
  const speakers = data.speakers;
  console.log(speakers);

  return (
    <div>
      <div className="container-fluid speaker-image ">
        <div className="row">
          <div className="col-md-12 text-center">
            <img
              src={require("../../assets/images/banner-2.png").default}
              alt="banner"
            />
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-12 ">
            <h4>
              <FontAwesomeIcon icon={faSquare} className="mr-3 text-primary" />{" "}
              Who can Join Us ?
            </h4>
            <div className="pl-5 ">
              <h5 className="mt-3 mb-3 text-primary">Female Speakers</h5>
              <p>
                Female speakers make up the core of the community. Use your free
                account to create a profile highlighting your background, your
                speaking experience, the topics you are passionate and
                knowledgeable about and your speaking engagement.
              </p>
              <h5 className="mt-3 mb-3 text-primary">Organisers</h5>
              <p>
                Conference and Event Organizers can join the community. Use your
                free account to post events and to search for Female Speakers
                and connect directly to speakers to invite them to speak.
              </p>
            </div>
          </div>

          <div className="col-12">
            <h4 className="mt-4 mb-4">
              <FontAwesomeIcon icon={faSquare} className="mr-3 text-primary" />
              Featured Speakers
            </h4>
            <div className="row row-eq-height">
              {speakers &&
                speakers.map(
                  (speaker, i) =>
                    speaker.featured && (
                      <div className="col-md-4 mb-4" key={i}>
                        <Link
                          to={"/speaker/" + speaker.handle}
                          className="bio-nav-link"
                        >
                          <div className="card h-100">
                            <div className="card-body text-center">
                              <div className="image_ava mb-3">
                                <img
                                  src={speaker.image}
                                  className="img-thumbnail rounded-circle mx-auto d-block"
                                  alt="avatar"
                                  style={{ maxWidth: "80%" }}
                                />
                              </div>

                              <h4 className="card-title text-center text-dark">
                                {speaker.fname + " " + speaker.lname}
                              </h4>
                              <span className="pink-color text-center">
                                {speaker.role}, {speaker.org}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageDetails;
