import React from "react";
import { FETCH_EVENTS } from "../../graphql/queries";
import { useQuery } from "@apollo/react-hooks";
import EventsListItem from "./EventsListItem";
import Loader from '../../components/loader/Loader';
import "../../styles/events/EventsPage.css";

const EventPageDetails = () => {
  const { loading, error, data } = useQuery(FETCH_EVENTS);

  if (loading) return <Loader />;
  
  if (error) return `Error! ${error.message}`;
  const events = data.events;
  events.sort(function(a, b){      
    return a.priority - b.priority;
    // return new Date(a.event_date) - new Date(b.event_date);
  });

 return (
    <div className="container">
      <div className="row pt-3 pb-3">
        {events &&
          events.map((event, i) => 
          event.is_active &&
          (
            <div className="col-md-12 mb-4" key={i}>
              <EventsListItem event={event} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default EventPageDetails;