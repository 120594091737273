import React from "react";
import "../../styles/partners/PartnersPage.css";
// import Loader from '../../components/loader/Loader';
// import { Link } from 'react-router-dom';

function PartnersPage() {
  return (
    <div style={{ minHeight: "calc(100vh - 177px)" }}>
      <div className="container-fluid about-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="white-color" style={{ marginTop: "10vh" }}>
                Partners
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="row mt-5 mb-5">
            <div className="col-md-12 text-center">
              <p>
                Our partners are organizations that are committed to providing
                more visibility and opportunities for female speakers. Contact
                us to become a partner.
              </p>
            </div>
          </div>

          <div className="row justify-content-md-center mt-5 mb-5">
            <div className="col-md-4">
              <div className="card mb-3">
                <a
                  href="https://iotcommunity.net/women-in-iot/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={
                      require("../../assets/images/IoTCommunityLogo.png")
                        .default
                    }
                    className="card-img-top"
                    alt="IoTCommunity Logo"
                  />
                </a>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card mb-3">
                <a
                  href="https://www.phizzle.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={require("../../assets/images/Phizzlelogo.png").default}
                    className="card-img-top"
                    alt="PhizzleLogo"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-3">
                <a href="https://briia.io/" target="_blank" rel="noreferrer">
                  <img
                    src={require("../../assets/images/briialogo.png").default}
                    className="card-img-top"
                    alt="briia logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-3">
                <a
                  href="https://ewowglobal.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={require("../../assets/images/ewow.png").default}
                    className="card-img-top"
                    alt="briia logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-3">
                <a
                  href="https://www.cbtechinc.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={require("../../assets/images/CBTLogo.png").default}
                    className="card-img-top"
                    alt="CBT logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-3">
                <a
                  href="https://www.purplepatchservices.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={require("../../assets/images/purplepatch.png").default}
                    className="card-img-top"
                    alt="CBT logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnersPage;
