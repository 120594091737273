import React from 'react';
import PasswordChgDetails from '../../components/users/PasswordChgDetails';
import '../../styles/speakers/SpeakerPage.css';

const PasswordChangeForm = () => {

  return (
    <div>
      <PasswordChgDetails />
    </div>
  )
}
export default PasswordChangeForm;