import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import "../../styles/speakers/SpeakersPage.css";

const SpeakersListItem = (props) => {
  const speaker = props.speaker;
  const profile_link = "/speaker/" + speaker.handle;
  const name = speaker.fname + " " + speaker.lname;

  return (
    <div className="profile-item-container">
      <div className="profile-info_svg">
        <img
          src={require("../../assets/images/profileWaveBg.svg").default}
          alt="profile bg"
        />
      </div>
      <div className="profile-img-wrap">
        <div className="profile-img">
          <Link to={profile_link}>
            <img src={speaker.image} alt="profile bg" />
          </Link>
        </div>

        <div className="profile-social-icons-wrap">
          {speaker.twitter !== "" && speaker.twitter ? (
            <a
              href={speaker.twitter}
              target="_blank"
              rel="noreferrer"
              className="profile-social-icon"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          ) : (
            <a rel="noreferrer" className="profile-social-icon disabled-link">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          )}

          {speaker.facebook !== "" && speaker.facebook ? (
            <a
              href={speaker.facebook}
              target="_blank"
              rel="noreferrer"
              className="profile-social-icon "
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          ) : (
            <a rel="noreferrer" className="profile-social-icon disabled-link">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          )}

          {speaker.facebook !== "" && speaker.facebook ? (
            <a
              href={speaker.instagram}
              target="_blank"
              rel="noreferrer"
              className="profile-social-icon"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          ) : (
            <a rel="noreferrer" className="profile-social-icon  disabled-link">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          )}

          {speaker.linkedIn !== "" && speaker.linkedIn ? (
            <a
              href={speaker.linkedIn}
              target="_blank"
              rel="noreferrer"
              className="profile-social-icon"
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
          ) : (
            <a rel="noreferrer" className="profile-social-icon disabled-link">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
          )}
        </div>
      </div>

      <div className="profile-info pt-2">
        <h4>
          <Link to={profile_link} className="text-dark">
            {name}
          </Link>
        </h4>
        <p className="text-primary">{speaker.role}, {speaker.org}</p>
        <small className="text-muted">{speaker.bio}</small>
      </div>
    </div>
  );
};

export default SpeakersListItem;
