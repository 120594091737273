import React from 'react';
import { FETCH_EVENTS } from '../../graphql/queries';
import { useQuery } from '@apollo/react-hooks';


const EventList = ({ setEvent }) => {

  const { loading, error, data } = useQuery(FETCH_EVENTS);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <select name="event" onChange={setEvent} className="form-control">
      {data.events.map(event => (
        <option key={event._id} value={event._id}>
          {event.name}
        </option>
      ))}
    </select>
  );
}

export default EventList;