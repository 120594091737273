import React from 'react';
import EventDetails from '../../components/events/EventDetails';

const EventForm = () => {

  return (
   
        <EventDetails />
  
  )
}
export default EventForm;