import React from "react"; 
import HomePageDetails from "../../components/homepage/HomePageDetails";
import '../../styles/homepage/HomePage.css';

const HomePage = () => {
  return(
    <div className="home_page_main">
      <HomePageDetails /> 
    </div>
  )
}
export default HomePage;