import React from "react";

import Navbar from "../../../node_modules/react-bootstrap/Navbar";
import Nav from "../../../node_modules/react-bootstrap/Nav";
import NavLink from "../../../node_modules/react-bootstrap/NavLink";

import LoginButton from "../session/LoginBtn";
import ProfileDropdown from '../session/ProfileDropdown';

import ProtectedComp from "../util/ProtectedComponent";
import AuthComp from "../util/AuthComponent";


const NavBar = () => {
  Nav.Link = NavLink;
  
  return (
    <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
      <div className="container">
        <Navbar.Brand href="/">
          <img src={require("../../assets/images/ssbLogoWhite.png").default} alt="SSBLogo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto navbar-nav align-items-center">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/speakers">Speakers </Nav.Link>
            <Nav.Link href="/events">Events</Nav.Link>
            <Nav.Link href="/about">About Us</Nav.Link>
           
            <Nav.Link href="/partners" className="mr-3">
              Partners
            </Nav.Link>
            
            <ProtectedComp component={ProfileDropdown} />

      


            <Nav.Item className="d-flex align-items-center">
              <AuthComp component={LoginButton} />
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>

    
  );
};

export default NavBar;